@use "constants" as constants;

@keyframes sweep {
    0% {
        opacity: 0;
        margin-top: -10px;
    }

    100% {
        opacity: 1;
        margin-top: 0px;
    }
}

html {
    height: 100%;
    box-sizing: border-box;
}

* {
    box-sizing: inherit;

    &:before {
        box-sizing: inherit;
    }

    &:after {
        box-sizing: inherit;
    }
}

body {
    position: relative;
    margin: 0;
    color: constants.$text-color;
    font-size: constants.$font-size-base;
    min-height: 100%;
    padding-bottom: 20rem !important;
    padding-top: 6rem !important;
    background-color: #cae6f4;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 36px;
        height: 30px;
        right: 36px;

        >button {
            box-shadow: none !important;
        }

        &:focus {
            outline: 0;
        }
    }

    .shrink {
        .bm-burger-button {
            width: 28px;
            height: 22px;
            right: 20px;
            top: 15px;
        }
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        border-radius: 2px;
        transition: constants.$base-transition;

        &:focus {
            outline: 0;
        }
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: constants.$link-color !important;
        opacity: 1 !important;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;

        >button {
            box-shadow: none !important;

            &:focus {
                outline: 0;
            }

            transition: constants.$base-transition;

            &:hover {
                background: constants.$link-color;
            }
        }
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        padding: 3em 3em 0;
        font-size: constants.$font-size-medium;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        background-color: constants.$theme-white;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 14px;
        height: unset !important;
        display: contents;

        >a:focus {
            outline: 0;
        }

        .summary {
            &:hover {
                color: constants.$link-color;
            }

            &::-webkit-details-marker {
                display: none;
            }

            &:after {
                display: none !important;
            }

            margin-block-end: 0 !important;
        }

        .bm-item {
            padding: unset !important;
            font-size: constants.$font-size-xl;
            width: 100%;
            text-align: left;
            margin-bottom: 20px;

            &:focus {
                outline: 0;
            }

            >details>a {
                padding: 9px 30px;
                font-size: constants.$font-size-large;
                width: 100%;
                text-align: left;
            }
        }
    }

    /* Styling of overlay */
    .bm-overlay {
        left: 0;
        top: 0;
        background-color: rgba(21, 21, 21, 50%);
    }
}

button {
    color: constants.$theme-white;
    border: none;
    font-weight: bold;
    padding: 0.6rem 0.75rem;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: constants.$base-transition;
    margin-bottom: 3px;
}

button,
select {
    &:hover {
        cursor: pointer;
    }
}

input,
textarea,
select {
    width: 100%;
    padding: 8px;
    border: 1px solid constants.$border-color;
    box-sizing: border-box;
    margin: 5px 0;
    color: grey;
    resize: vertical;
}

i {
    font-family: "FontAwesome" !important;
    font-style: unset;
}

.anchor {
    display: block;
    height: 52px;
    margin-top: -52px;
    visibility: hidden;
}

button:focus,
input:focus,
select:focus {
    outline: none !important;
}

.left-align {
	img {
		float: left;
		height: 260px;
		width: auto;
		margin-left: -15px;
	}
}
.right-align {
	img {
		float: right;
		height: 260px;
		width: auto;
		margin-right: -10px;
	}
}

.under-new-management-button {
    background-color: constants.$under-new-management-color !important;

    &:hover {
        background-color: constants.$under-new-management-color-hover !important;
    }
}

.task-force-button {
    background-color: constants.$task-force-color !important;

    &:hover {
        background-color: constants.$task-force-color-hover !important;
    }
}

.field-office-button {
    background-color: constants.$field-office-color !important;

    &:hover {
        background-color: constants.$field-office-color-hover !important;
    }
}

a {
    color: constants.$link-color;

    &:hover {
        color: constants.$link-color-hover;
    }
}

button:disabled,
button[disabled] {
    background-color: #cccccc !important;
}

.required-skill-points {
    img {
        width: 35px !important;
    }
}

.gag-row {
    display: flex;
    justify-content: space-around;
}

.social-links {
    display: flex;
    flex-direction: row;

    .coffee {
        font-size: constants.$font-size-xs;
        border: 1px solid constants.$theme-white;
        margin-right: 10px;
        margin-top: 4px;
        height: 21px;
        padding-top: 1px !important;

        &:hover {
            border-color: constants.$link-color !important;
        }
    }
}

.ReactModal__Overlay  {
    z-index: 1;
}

.ipr-container {
    border-top: unset !important;
    border-bottom: unset !important;
    background-color: transparent !important;
}

.whatsnew-popup {
    position: absolute;
    text-align: center;
    margin-top: 10em;
    width: 600px;
    background: rgb(255, 255, 255);
    overflow: auto;
    padding: 5px 30px 17px 30px;
    outline: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    &:focus {
        outline: 0;
    }
}

h1, h2, h3 {
    color: constants.$text-color;
    font-weight: 400;

    a {
        color: constants.$text-color;
    }
}

summary {
    color: constants.$text-color;
    font-weight: 400;
    display: block;
    font-size: 32px;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th {
    vertical-align: bottom;
    text-align: center;
    border-bottom: 1px solid constants.$border-color;
    padding: 3px;
}

td {
    text-align: center;
    border-bottom: 1px solid constants.$border-color;
    padding: 3px;
}

tbody {
    tr {
        td {
            img {
                vertical-align: middle;
                height: auto;
                width: 80px;
            }
        }

        transition: background-color 0.15s linear;

        &:hover {
            background-color: #f5f5f5;
        }
    }
}

tr {
    th {
        img {
            width: 30px;
            height: auto;
        }
    }
}

.warning-message {
    border: 1px solid constants.$border-color;
    padding: 0 12px 7px 16px;
    border-left: 6px solid constants.$link-color;
}

.shrink {
    position: fixed;
    margin-bottom: 50px;
    height: 52px;
    background-color: rgb(255, 255, 255);

    a {
        font-size: constants.$font-size-large;
    }

    .dropdown {
        .dropbtn {
            font-size: constants.$font-size-large;
        }
    }

    .dropdown-content a {
        font-size: constants.$font-size-medium;
    }

    .nav-search {
        input {
            display: none;
            visibility: hidden;
        }

        padding: 0 15px !important;
    }
}

nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    transition: constants.$base-transition;
    overflow: visible;
    z-index: 1;

    img {
        margin: -30px -50px;
        padding: 10px 85px 0 10px;
        float: left;
        height: 85px;
        width: auto;
        cursor: pointer;
    }

    a,
    button {
        cursor: pointer;
        float: left;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        font-size: constants.$font-size-xl;
        transition: constants.$base-transition;

        &:hover {
            color: constants.$link-color !important;
        }
    }

    .nav-search {
        right: 0;
        text-align: right;
        width: 405px;
        position: absolute;
        transition: constants.$base-transition;

        .search-btn {
            float: right;
        }

        input {
            width: 80%;
        }
    }

    .dropdown-content {
        display: none;
        position: absolute;
        width: auto;
        z-index: 1;
        background-repeat: no-repeat;

        a {
            font-weight: 400;
            text-decoration: none;
            text-align: center;
            font-size: constants.$font-size-large;

            &:hover {
                color: constants.$link-color !important;
            }
        }
    }

    .dropdown {
        transition: constants.$base-transition;

        &:hover {
            .dropdown-content {
                display: block;
            }
        }
    }

    .mobile-nav {
        visibility: hidden;
        display: none;
    }
}

.pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;

    li {
        width: 40px;
    }

    a {
        font-size: constants.$font-size-small;
        transition: constants.$base-transition;

        &:hover {
            cursor: pointer;
            color: constants.$link-color !important;
        }
    }

    .item {
        align-items: center;
        display: flex;
        font-size: constants.$font-size-small;
        height: 40px;
        justify-content: center;
        margin: 0 15px;
        transition: constants.$base-transition;

        a {
            color: constants.$theme-white !important;
            font-weight: bold;
        }

        i {
            font-size: constants.$font-size-large;
            transition: constants.$base-transition;
        }
    }

    .active {
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        color: constants.$theme-white !important;
        cursor: pointer;
    }

    .next,
    .previous {
        position: absolute;
        width: 50px;

        &:hover {
            background-color: unset !important;
        }

        i:hover {
            color: constants.$link-color !important;
        }
    }

    .next {
        right: 0;
    }

    .previous {
        left: 0;
    }

    .disabled i {
        color: constants.$border-color !important;

        &:hover {
            color: constants.$border-color !important;
        }
    }
}

// search sass
.search {
    padding-bottom: 15px;

    input {
        width: calc(100% - 45px);
        margin-right: 5px;
    }

    .search-btn {
        color: constants.$theme-white !important;
        padding: 0.6rem 0.75rem !important;
        font-size: constants.$font-size-small !important;
        -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: constants.$base-transition;
    }
}

.search-results {
    margin-top: 20px;

    .page-link {
        font-size: constants.$font-size-medium !important;
    }

    .results-link {
        margin-top: 7px;

        >a {
            text-decoration: none;
            color: #a0a0a0;
            transition: constants.$base-transition;

            &:hover {
                color: constants.$link-color;
                text-decoration: underline;
            }
        }
    }
}
// ---

.dropdown {
    float: left;
    overflow: hidden;
    transition: constants.$base-transition;

    .dropbtn {
        cursor: pointer;
        font-weight: 400;
        font-size: constants.$font-size-xl;
        border: none;
        outline: none;
        margin: 0;
        transition: constants.$base-transition;
        background-color: inherit;
    }

    &:hover {
        .dropbtn {
            color: constants.$link-color;
        }
    }
}

.logo {
    img {
        display: block;
        padding-top: 6em;
        max-width: 85em;
        width: 100%;
        margin: -2em auto;
    }
} 

.backtotop-link {
    position: fixed;
    opacity: 0;
    transition: constants.$base-transition;
    visibility: hidden; /* Initially hidden */
    top: calc(100vh - 115px);
    right: 5px;
    margin: 35px;
    transition: constants.$base-transition;

    &.show {
        visibility: visible !important; /* Change to block to make it visible */
        opacity: 1 !important;
        z-index: 1;
    }
}

.panel {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65em;
    margin-top: 6em;
    margin-bottom: 2em;
    background-color: constants.$theme-white;

    .panel-content {
        padding: 5px 30px 17px 30px;
    }

    .panel-nav {
        display: flex;
        justify-content: space-around;
        text-align: center;

        >a {
            padding: 0 3px;
        }

        >button {
            margin-right: 10px;

            &:last-child {
                margin-right: unset;
            }
        }
    }

    .book-tab {
        margin-bottom: -6px;
        width: auto;
        height: 23px;
    }

    .content-img {
        display: block;
        margin: auto;
        margin-bottom: 10px;
        width: auto;
        max-width: 100%;
        padding: 0 5px;
    }

    .row-images {
        padding: unset !important;

        a {
            text-decoration: none;

            h2 {
                transition: constants.$base-transition;
                margin: 15px 0 !important;
            }

            &:hover {
                h2 {
                    color: constants.$link-color;
                }
            }
        }
    }
}

.footer-panel {
    position: absolute;
    padding: 60px 30px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 271px;
    width: 100%;
    margin-top: 7em;
    font-size: constants.$font-size-small;
    vertical-align: top;
    color: constants.$theme-white;
    transition: constants.$base-transition;

    .site-title {
        font-size: constants.$font-size-medium;
    }

    .site-description {
        padding-top: 15px;
        color: rgba(255, 255, 255, 0.7);
        word-wrap: break-word;
    }

    .column {
        max-width: 300px;
    }

    a {
        text-decoration: none;
        padding: 0 7px;
        transition: constants.$base-transition;
        color: constants.$theme-white !important;

        &:hover {
            color: constants.$link-color !important;
        }
    }

    .theme-select {
        padding: 1px 7px !important;
        font-size: constants.$font-size-xs;
        width: 50%;
    }
}

.row {
    display: flex;
    padding: 0 25px;
}

.column {
    flex: 33.33%;
}

.badge {
    display: block;
    float: right;
    margin: -30px 0 0 5px;
    height: 80px;
    width: auto;
}

.video-frame {
    margin: 0 auto;
    display: block;
    border: none;
}

details[open] {
    summary~* {
        animation: sweep 0.5s ease-in-out;
    }

    summary:after {
        content: "-";
    }
}

.disclaimer {
    text-align: center;
    font-size: constants.$font-size-small;
    width: 100%;
}

summary {
    &:focus {
        outline: 0;
    }

    &:hover {
        cursor: pointer;
    }

    &::-webkit-details-marker {
        display: none;
    }

    &:after {
        content: "+";
        float: left;
        margin-right: 15px;
        font-size: 48px;
    }
}

.list {
    ul {
        background-color: #ffffff;

        .bean-colors {
            padding: 10px 5px 0 5px;
        }
    }

    .filtering {
        button {
            margin: 0 10px;
        }

        .active {
            background-color: constants.$link-color;

            &:hover {
                background-color: constants.$link-color;
                cursor: default;
            }
        }
    }
}

.mobile-fish-legend {
    visibility: hidden;
    display: none;
}

.mobile-button-nav {
    visibility: hidden;
    display: none;
}

.web-nav {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1075px) {
    .panel {
        width: 95%;
    }
}

@media screen and (max-width: 520px) {
    .content-img {
        height: auto !important;
    }

    .backtotop-link {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 670px) {
    .video-frame {
        width: 100% !important;
    }
}

@media screen and (max-width: 660px) {
    .web-fish-legend {
        visibility: hidden;
        display: none;
    }

    .mobile-fish-legend {
        visibility: visible;
        display: block;
    }

    .whatsnew-popup {
        width: 95%;
    }
}

@media screen and (max-width: 750px) {
    .row-images {
        display: flex;
        flex-direction: column;

        .content-img {
            max-height: 250px !important;
            height: auto !important;
        }
    }

    .footer-panel {
        height: 300px;
        padding: 50px 20px;

        .theme-select-container {
            display: none;
            visibility: hidden;
        }

        .main-pages {
            display: none;
            visibility: hidden;
        }

        .site-info {
            flex: 60% !important;
        }

        .footer-pages {
            flex: 40% !important;
        }
    }
}

@media screen and (max-width: 1500px) {
    .nav-search {
        input {
            width: 60% !important;
        }
    }
}
