@use "constants" as constants;
@import 'theme';

.dark-mode {
    font-family: constants.$dark-font-family;
    background: url(../assets/images/cogs/bossbots/Clubhouselobby.png);

    button {
        font-family: constants.$dark-font-family;
        border-radius: constants.$dark-border-radius;
        background-color: constants.$dark-base-color;

        &:hover {
            background-color: constants.$dark-base-color-hover;
        }
    }

    input,
    textarea,
    select {
        font-family: constants.$dark-font-family;
        border-radius: constants.$dark-border-radius;
    }

    .bm-burger-bars {
        background: constants.$dark-base-color;
    }

    .bm-menu-wrap {
        margin-top: -7px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: constants.$dark-base-color;
    }

    .bm-burger-button {
        top: 28px;
    }

    .bm-item-list {
        .summary {
            color: constants.$dark-base-color;
            font-weight: 400;
            font-size: constants.$font-size-xl;
            transition: constants.$base-transition;
        }
    }

    .ipr-container {
        font-family: constants.$dark-font-family !important;
    }

    .whatsnew-popup {
        border: 15px solid transparent;
        border-image: url(../assets/images/cogs/metal_border.png) 30;
    }

    h1, h2, h3 {
        font-family: constants.$dark-font-heading;
    }

    summary {
        font-family: constants.$dark-font-heading;
    }

    .social-links .coffee {
        border-radius: constants.$dark-border-radius;
    }

    .warning-message {
        border-radius: constants.$dark-border-radius;
    }

    .shrink {
        box-shadow: 0 1px 6px 0 constants.$dark-box-shadow-color;

        a {
            padding: 6px 30px 5px 30px;
        }

        img {
            margin: -12px -50px;
            padding: 2px 75px 0 12px;
            height: 50px;
        }

        .dropdown {
            .dropbtn {
                padding: 6px 27px 5px 30px;
            }
        }

        .dropdown-content {
            margin: 35px 35px 35px -15px;
            padding: 12px 55px 45px 27px;
            background-size: 100% 90px;

            a {
                padding: 20px 12px 12px 12px;
            }
        }

        .nav-search .search-btn {
            margin-top: 2px;
        }
    }

    nav {
        padding: 7px 15px;

        a,
        button {
            color: constants.$dark-base-color;
            font-family: constants.$dark-font-heading;
            padding: 21px 40px 20px 40px;
        }

        .nav-search {
            padding: 16px 15px 0 15px;

            .search-btn {
                padding: 0.5rem 0.7rem !important;
            }
        }

        .dropdown-content {
            margin-top: 65px;
            padding: 0 35px 75px 28px;
            background-image: url(../assets/images/cogs/bossbots/red_oil.png);
            background-size: 100% 130px;

            a {
                color: constants.$dark-base-color-hover;
                padding: 50px 20px 20px 20px;
                font-family: constants.$dark-font-heading;
            }
        }
    }

    .pagination {

        a {
            color: constants.$dark-base-color;
        }

        .item {

            &:hover {
                background-color: constants.$dark-base-color-hover;
            }

            i {
                color: constants.$dark-base-color;
            }
        }

        .active {
            border-radius: constants.$dark-border-radius;
            background-color: constants.$dark-base-color;
        }

        .next,
        .previous {
            color: constants.$dark-base-color;
        }
    }

    // search sass
    .search {

        .search-btn {
            background-color: constants.$dark-base-color;
            border-radius: constants.$dark-border-radius;
            margin-top: 4px;

            &:hover {
                background-color: constants.$dark-base-color-hover;
            }
        }
    }
    // ---

    .dropdown {

        .dropbtn {
            font-family: constants.$dark-font-heading;
            padding: 21px 37px 20px 40px;
            color: constants.$dark-base-color;
        }
    }

    .logo {

        .cogImg {
            display: block;
            visibility: visible;
        }

        .toonImg {
            display: none;
            visibility: hidden;
        }
    }

    .panel {
        border: 15px solid transparent;
        border-image: url(../assets/images/cogs/metal_border.png) 30;
        box-shadow: 1px 1px 8px 1px constants.$dark-box-shadow-color;

        .content-img {
            border-radius: 5px;
        }
    }

    .footer-panel {
        background-color: constants.$dark-base-color;
    }

    .badge {
        border-radius: 5px;
    }

    .video-frame {
        border-radius: 5px;
    }

    .disclaimer {
        color: constants.$dark-base-color;
    }

    summary:after {
        margin-top: -15px;
    }

    .list {
        ul {
            font-family: constants.$dark-font-heading;
            border: 15px solid transparent;
            border-image: url(../assets/images/cogs/metal_border.png) 30;
            box-shadow: 1px 1px 8px 1px rgb(61, 61, 61);

            table tr td,
            .bean-colors {
                font-family: constants.$dark-font-family;
            }
        }

        .filtering {
            button {
                font-family: constants.$dark-font-family;
                background-color: constants.$dark-base-color;
                border-radius: constants.$dark-border-radius;

                &:hover {
                    background-color: constants.$dark-base-color-hover;
                }
            }
        }
    }

    @media screen and (max-width: 765px) {
        .web-button-nav {
            visibility: hidden;
            display: none !important;
        }

        .mobile-button-nav {
            visibility: visible;
            display: block;
        }
    }

    @media screen and (max-width: 1158px) {
        nav {
            .web-nav {
                visibility: hidden;
                display: none;
            }

            .mobile-nav {
                visibility: visible;
                display: flex;
            }
        }
    }

    @media screen and (max-width: 1430px) {
        .nav-search {
            width: auto !important;

            input {
                z-index: -1;
                display: none;
                visibility: none;
            }
        }
    }
}