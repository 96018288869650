@use "constants" as constants;
@import 'theme';

.light-mode {
    font-family: constants.$light-font-family;
    background: url(../assets/images/ttc-background.png);

    button {
        font-family: constants.$light-font-family;
        border-radius: constants.$light-border-radius;
        background-color: constants.$light-base-color;

        &:hover {
            background-color: constants.$light-base-color-hover;
        }
    }

    input,
    textarea,
    select {
        font-family: constants.$light-font-family;
        border-radius: constants.$light-border-radius;
    }

    .bm-burger-bars {
        background: constants.$light-base-color;
    }

    .bm-menu-wrap {
        margin-top: -5px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: constants.$light-base-color;
    }

    .bm-burger-button {
        top: 36px;
    }

    .bm-item-list {
        .summary {
            color: constants.$light-base-color;
            font-weight: 400;
            font-size: constants.$font-size-xl;
            transition: constants.$base-transition;
        }
    }

    .ipr-container {
        font-family: constants.$light-font-family !important;
    }

    .whatsnew-popup {
        border-radius: constants.$light-border-radius;
    }

    h1, h2, h3 {
        font-family: constants.$light-font-heading;
    }

    summary {
        font-family: constants.$light-font-heading;
    }

    .social-links .coffee {
        border-radius: constants.$light-border-radius;
    }

    .warning-message {
        border-radius: constants.$light-border-radius;
    }

    .shrink {
        box-shadow: 0 1px 6px 0 constants.$light-box-shadow-color;

        a {
            padding: 9px 30px;
        }

        img {
            margin: -9px -50px;
            padding: 0 75px 0 12px;
            height: 45px;
        }

        .dropdown {
            .dropbtn {
                padding: 9px 27px 9px 30px;
            }
        }

        .dropdown-content {
            margin: 30px 0 0 -20px;
            padding: 0 55px 45px 24px;
            background-size: 100% 98px;

            a {
                padding: 40px 17px 17px 17px;
            }
        }
    }

    nav {
        padding: 5px 15px;

        a,
        button {
            color: constants.$light-base-color;
            font-family: constants.$light-font-heading;
            padding: 30px 35px;
        }

        .nav-search {
            padding: 23px 15px 8px 15px;
        }

        .dropdown-content {
            margin: 60px 0 0 -30px;
            padding: 0 60px 50px 27px;
            background-image: url(../assets/images/cartoon-cloud.png);
            background-size: 100% 150px;

            a {
                color: constants.$light-base-color;
                padding: 67px 20px 30px 20px;
                font-family: constants.$light-font-heading;
            }
        }
    }

    .pagination {

        a {
            color: constants.$light-base-color;
        }

        li {
            text-align: center;
        }

        .item {

            &:hover {
                background-color: constants.$light-base-color-hover;
            }

            i {
                color: constants.$light-base-color;
            }
        }

        .active {
            border-radius: 12px;
            background-color: constants.$light-base-color;
        }

        .next,
        .previous {
            color: constants.$light-base-color;
        }
    }

    // search sass
    .search {

        .search-btn {
            background-color: constants.$light-base-color;
            border-radius: 12px;
            margin-top: 3px;

            &:hover {
                background-color: constants.$light-base-color-hover;
            }
        }
    }
    // ---

    .dropdown {

        .dropbtn {
            font-family: constants.$light-font-heading;
            padding: 30px 32px 30px 35px;
            color: constants.$light-base-color;
        }
    }

    .logo {

        .toonImg {
            display: block;
            visibility: visible;
        }

        .cogImg {
            display: none;
            visibility: hidden;
        }
    }

    .panel {
        border-radius: constants.$light-border-radius;
        box-shadow: 1px 1px 8px 1px constants.$light-box-shadow-color;

        .content-img {
            border-radius: constants.$light-border-radius;
        }
    }

    .footer-panel {
        background-color: constants.$light-base-color;
    }

    .badge {
        border-radius: constants.$light-border-radius;
    }

    .video-frame {
        border-radius: constants.$light-border-radius;
    }

    .disclaimer {
        color: constants.$light-base-color;
    }

    summary:after {
        margin-top: -12px;
    }

    .list {
        ul {
            font-family: constants.$light-font-heading;
            border-radius: constants.$light-border-radius;
            box-shadow: 1px 1px 8px 1px constants.$light-box-shadow-color;

            table tr td,
            .bean-colors {
                font-family: constants.$light-font-family;
            }
        }

        .filtering {
            button {
                font-family: constants.$light-font-family;
                background-color: constants.$light-base-color;
                border-radius: 12px;

                &:hover {
                    background-color: constants.$light-base-color-hover;
                }
            }
        }
    }

    @media screen and (max-width: 735px) {
        .web-button-nav {
            visibility: hidden;
            display: none !important;
        }

        .mobile-button-nav {
            visibility: visible;
            display: block;
        }
    }

    @media screen and (max-width: 1140px) {
        nav {
            .web-nav {
                visibility: hidden;
                display: none;
            }

            .mobile-nav {
                visibility: visible;
                display: flex;
            }
        }
    }

    @media screen and (max-width: 1420px) {
        .nav-search {
            width: auto !important;

            input {
                z-index: -1;
                display: none;
                visibility: none;
            }
        }
    }
}
