@use "constants" as constants;

/** list css */
body {
	opacity: 1;
	transition: 0.7s opacity;
}
body.fade {
	opacity: 0;
	transition: none;
}
.list {
	ul {
		display: block;
		margin-left: auto;
		margin-right: auto;
		list-style-type: none;
		font-weight: 400;
		font-size: constants.$font-size-medium;
		width: 30em;
		height: auto;
		text-align: center;
		margin-bottom: 15px;
		padding: 20px 0 20px 0;
		table {
			tr {
				td {
					font-size: constants.$font-size-base;
				}
				th {
					font-weight: 400;
				}
			}
			td {
				&:not(:last-child) {
					border-right: 1px solid constants.$border-color;
				}
			}
		}
		img {
			padding: 15px 0 0 0;
		}
	}
	.filtering {
		button {
			box-sizing: border-box;
			font-size: constants.$font-size-base;
			cursor: pointer;
		}
		display: flex;
		justify-content: center;
		margin-bottom: -10px;
	}
	h2 {
		text-align: center;
		margin-bottom: -15px;
		padding-top: 20px;
	}
	margin-bottom: 3em;
}
.list-navigation {
	color: constants.$link-color;
	font-size: constants.$font-size-small;
	text-align: center;
	margin: -25px 0 2.5em 0;
	padding: 0 25px;
}
.list-fish {
	width: 125px;
	height: auto;
}
@media screen and (max-width: 650px) {
	.list {
		ul{
			width: 90%;
		}
	}
}
