// light-theme
$light-base-color: #5aaaff;
$light-base-color-hover: #3f92eb;
$light-border-radius: 1em;
$light-box-shadow-color: rgba(118, 118, 118, 0.25);
$light-font-family: "Roboto", sans-serif;
$light-font-heading: "Luckiest Guy", cursive;

// dark-theme
$dark-base-color: #333333;
$dark-base-color-hover: #1d1b1b;
$dark-border-radius: 3px;
$dark-box-shadow-color: rgba(61, 61, 61, 0.5);
$dark-font-family: "Raleway", sans-serif;
$dark-font-heading: "Stardos Stencil", cursive;

// theme
$theme-white: #ffffff;
$text-color: #333;
$link-color: red;
$link-color-hover: #cc0000;
$border-color: #ccc;

// font sizes
$font-size-xl: 25px;
$font-size-large: 20px;
$font-size-medium: 18px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-xs: 11px;

// misc
$base-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
$base-transition: all 0.3s ease-in-out;

$under-new-management-color: #ed4828;
$under-new-management-color-hover: #d13f2a;
$task-force-color: #2fa966;
$task-force-color-hover: #2a8b5f;
$field-office-color: #765673;
$field-office-color-hover: #6a4f6a;
